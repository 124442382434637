<template>
	<v-container v-if="walkPost" fluid>
		<v-card class="mx-auto" max-width="550px">
			<v-img
				class="blue--text align-end"
				max-height="300px"
				:src="`${walkPost.image}`"
			>
				<v-card-subtitle>{{ convertDate(walkPost) }}</v-card-subtitle>
			</v-img>

			<v-card-title> {{ walkPost.title }} </v-card-title>
			<v-card-text class="text--primary">
				<div class="text-preview">{{ walkPost.description }}</div>
			</v-card-text>
			<v-card-text class="text--primary">
				<div class="text-preview">
					{{ walkPost.city }}, {{ walkPost.district }}
				</div>
				<div>
					{{ walkPost.contact }}
				</div>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn color="grey" dark @click="$router.go(-1)">
					{{ $t('back') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import moment from 'moment';

export default {
	name: 'WalkPost',

	data() {
		return {
			url: process.env.VUE_APP_BASE_URL || 'http://localhost:5000/api/v1',
			walkPost: null,
		};
	},
	methods: {
		convertDate(post) {
			return moment(post.createdAt).format('DD.MM.YYYY');
		},
	},
	async mounted() {
		this.isLoading = true;

		try {
			const res = await this.$http.get(
				`${this.url}/walkPosts/${this.$route.params.id}`,
			);

			this.walkPost = res.data.data;
		} catch (error) {
			console.error(error);
		} finally {
			this.isLoading = false;
		}
	},
};
</script>

<style></style>
